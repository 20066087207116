import { Capacitor } from "@capacitor/core";
import {
  Purchases as PurchasesNative,
  LOG_LEVEL as LOG_LEVEL_NATIVE,
  PURCHASES_ERROR_CODE as PURCHASES_ERROR_CODE_NATIVE,
  PurchasesOfferings as PurchasesOfferingsNative,
  PurchasesPackage as PurchasesPackageNative,
} from "@revenuecat/purchases-capacitor";
import {
  Purchases as PurchasesWeb,
  LogLevel as LogLevelWeb,
  Offerings as OfferingsWeb,
  Package as PurchasesPackageWeb,
  PurchasesError as PurchasesErrorWeb,
  ErrorCode as ErrorCodeWeb,
} from "@revenuecat/purchases-js";
import { revenuecatApiKey } from "./constants";
import { useUser } from "./hooks";
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "@remix-run/react";

async function initRevenueCat(userUlid: string) {
  if (Capacitor.isNativePlatform()) {
    return initRevenueCatNative(userUlid);
  }

  return initRevenueCatWeb(userUlid);
}

async function initRevenueCatWeb(userUlid: string) {
  if (revenuecatApiKey == null) {
    console.warn("No RevenueCat API key found");
    return;
  }

  if (import.meta.env.DEV) {
    console.log("Initializing RevenueCat with API key", revenuecatApiKey);
    PurchasesWeb.setLogLevel(LogLevelWeb.Debug);
  }

  PurchasesWeb.configure(revenuecatApiKey, userUlid);
}

async function initRevenueCatNative(userUlid: string) {
  if (revenuecatApiKey == null) {
    console.warn("No RevenueCat API key found");
    return;
  }

  if (import.meta.env.DEV) {
    console.log("Initializing RevenueCat with API key", revenuecatApiKey);
    await PurchasesNative.setLogLevel({ level: LOG_LEVEL_NATIVE.DEBUG });
  }

  await PurchasesNative.configure({
    apiKey: revenuecatApiKey,
    appUserID: userUlid,
  });
}

export function useInitRevenueCat() {
  const [ready, setReady] = useState(false);
  const userQuery = useUser();

  useEffect(() => {
    (async () => {
      if (userQuery.data) {
        await initRevenueCat(userQuery.data.ulid);
        setReady(true);
      }
    })();
  }, [userQuery.data]);

  return ready;
}

const RevenueCatReadyContext = createContext<boolean | null>(null);

export function RevenueCatProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const ready = useInitRevenueCat();

  return (
    <RevenueCatReadyContext.Provider value={ready}>
      {children}
    </RevenueCatReadyContext.Provider>
  );
}

export function useRevenueCatReady() {
  const ready = useContext(RevenueCatReadyContext);
  if (ready === null) {
    throw new Error(
      "useRevenueCatReady must be used within a RevenueCatProvider",
    );
  }
  return ready;
}

export type UniversalOfferings = PurchasesOfferingsNative | OfferingsWeb;

export function useRevenueCatOfferings() {
  const [offerings, setOfferings] = useState<UniversalOfferings | null>(null);
  const ready = useRevenueCatReady();

  useEffect(() => {
    (async () => {
      if (!ready) return;
      let _offerings: UniversalOfferings | null = null;
      if (Capacitor.isNativePlatform()) {
        _offerings = await PurchasesNative.getOfferings();
      } else {
        if (PurchasesWeb.isConfigured()) {
          _offerings = await PurchasesWeb.getSharedInstance().getOfferings();
        } else {
          _offerings = webOfferings;
        }
      }
      if (_offerings != null) {
        setOfferings(_offerings);
      }
    })();
  }, [ready]);

  return offerings;
}

export type UniversalPackage = PurchasesPackageNative | PurchasesPackageWeb;

export function useRevenueCatPurchase({
  onPurchased,
}: {
  onPurchased: () => void;
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  function purchase(pkg: UniversalPackage) {
    if (Capacitor.isNativePlatform()) {
      return purchaseNative(pkg as PurchasesPackageNative);
    } else {
      return purchaseWeb(pkg as PurchasesPackageWeb);
    }
  }

  async function purchaseWeb(pkg: PurchasesPackageWeb) {
    if (!PurchasesWeb.isConfigured()) {
      navigate("/paywall/web");
      return;
    }

    try {
      setLoading(true);
      const result = await PurchasesWeb.getSharedInstance().purchase({
        rcPackage: pkg,
      });
      if (Object.keys(result.customerInfo.entitlements.active).length > 0) {
        onPurchased();
      }
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoading(false);
      if (
        error instanceof PurchasesErrorWeb &&
        error.errorCode == ErrorCodeWeb.UserCancelledError
      ) {
        return;
      }
      alert(error.message ?? "An unknown error occurred. Please try again.");
    }
  }

  async function purchaseNative(pkg: PurchasesPackageNative) {
    try {
      setLoading(true);
      const result = await PurchasesNative.purchasePackage({ aPackage: pkg });
      if (Object.keys(result.customerInfo.entitlements.active).length > 0) {
        onPurchased();
      }
      setLoading(false);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoading(false);
      if (error.code === PURCHASES_ERROR_CODE_NATIVE.PURCHASE_CANCELLED_ERROR) {
        return;
      }

      alert(error.message ?? "An unknown error occurred. Please try again.");
    }
  }

  async function restore() {
    setLoading(true);
    try {
      const result = await PurchasesNative.restorePurchases();
      setLoading(false);
      if (Object.keys(result.customerInfo.entitlements.active).length === 0) {
        alert("No active subscriptions found. Please purchase a subscription.");
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (
        error.code === PURCHASES_ERROR_CODE_NATIVE.MISSING_RECEIPT_FILE_ERROR
      ) {
        setLoading(false);
        return;
      }

      throw error;
    }
  }

  return { purchase, restore, loading };
}

// Temporary until we have a real web offering setup
export const webOfferings = {
  current: {
    metadata: {
      benefits: {
        enterprise: {
          connections: 17,
        },
        premium: {
          connections: 7,
        },
      },
    },
    availablePackages: [
      {
        identifier: "premium",
        rcBillingProduct: {
          title: "Premium",
          currentPrice: {
            formattedPrice: "49.95€",
          },
        },
      },
      {
        identifier: "enterprise",
        rcBillingProduct: {
          title: "Enterprise",
          currentPrice: {
            formattedPrice: "99.95€",
          },
        },
      },
    ],
  } as unknown as OfferingsWeb,
} as unknown as OfferingsWeb;
